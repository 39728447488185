<template>
	<div class="myHome">
		<div class="homeLeft">
			<div class="headerLogo">
				<img src="../../assets/logo.png">
			</div>
			<el-menu :default-active="defaultIndex" class="el-menu-vertical-demo" @select="handleSelect"
				:collapse="isCollapse" background-color="#001529" text-color="#cccccc" active-text-color="#ffd04b">
				<el-menu-item index="1">
					<i class="el-icon-pie-chart"></i>
					<span slot="title">考试范围</span>
				</el-menu-item>
				<el-submenu index="2">
					<template slot="title">
						<i class="el-icon-document-copy"></i>
						<span>试题菜单</span>
					</template>
					<el-menu-item index="2-1">试题上传</el-menu-item>
					<el-menu-item index="2-2">试题列表</el-menu-item>
				</el-submenu>
				<el-menu-item index="3">
					<i class="el-icon-edit-outline"></i>
					<span slot="title">试卷管理</span>
				</el-menu-item>
				<!-- <el-menu-item index="5">
					<i class="el-icon-view"></i>
					<span slot="title">答题信息</span>
				</el-menu-item> -->
				<el-menu-item index="4">
					<i class="el-icon-refresh"></i>
					<span slot="title">切换考试端</span>
				</el-menu-item>
			</el-menu>
		</div>
		<div class="homeRight">
			<div class="menuNavTitle">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item v-for="item in breadcrumbItems">&nbsp;&nbsp;{{item}}</el-breadcrumb-item>
				</el-breadcrumb>
				<div>可帮考试后台管理系统</div>
				<div class="headerInfo">
					<div class="p1">
						<p>
							<i class="el-icon-user"></i>&nbsp;{{name}}
						</p>
					</div>
					<div class="p2" @click="editbtn">
						<p>
							<i class="el-icon-key"></i>&nbsp;修改密码
						</p>
					</div>
					<div class="p3" @click="exitbtn">
						<p>
							<i class="el-icon-switch-button"></i>&nbsp;退出系统
						</p>
					</div>
				</div>
			</div>
			<div class="rightContent">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
	import Cookies from 'js-cookie'
	history.pushState(null, null, document.URL);
	window.addEventListener('popstate', function() {
		history.pushState(null, null, document.URL);
	});
	export default {
		name: 'Home',
		data() {
			return {
				defaultIndex: "1",
				searchCriteria: '',
				breadcrumbItems: [],
				isCollapse: false,
				name: '',
				permission: '',
				time: '',
			}
		},
		created() {
			var that = this;
			clearInterval(that.time);
			that.time = setInterval(function() {
				if (Cookies.get("accessToken")) {
					clearInterval(that.time);
					that.getMeData();
				}
			}, 100);
		},
		watch: {
			$route: {
				handler: function(val, oldVal) {
					let that = this;
					that.routerMethods(val.name);
				}
			},
		},
		methods: {
			// /me 请求
			getMeData() {
				let that = this;
				that.loading = true;
				that.$axios
					.get("/me")
					.then(function(res) {
						that.loading = false;
						localStorage.setItem("userName", res.data.user.name);
						that.name = res.data.user.name;
						//存储权限
						if (res.data.permission) {
							var arr = JSON.stringify(res.data.permission);
							localStorage.setItem("permission", arr);
							that.permission = JSON.parse(arr);
						}
						var myrout = that.$route.name;
						that.routerMethods(myrout);
					})
					.catch(function(err) {
						that.loading = false;
					});
			},
			// 路由判断
			routerMethods(val) {
				var that = this;
				if (val == "QuizScope") {
					that.defaultIndex = "1";
					that.breadcrumbItems = ['考试范围'];
				} else if (val == "QuizUpload") {
					that.defaultIndex = "2-1";
					that.breadcrumbItems = ['试题上传'];
				} else if (val == "QuizList") {
					that.defaultIndex = "2-2";
					that.breadcrumbItems = ['试题列表'];
				} else if (val == "Exam") {
					that.defaultIndex = "3";
					that.breadcrumbItems = ['试卷管理'];
				}
				// else if (val == "QuizView") {
				// 	that.defaultIndex = "5";
				// 	that.breadcrumbItems = ['答题信息'];
				// }
				else {
					that.defaultIndex = "1";
					that.$router.push('/quizScope');
					that.breadcrumbItems = ['考试范围'];
				}	
			},
			getData() {
				var that = this;
				that.name = localStorage.getItem("userName");
				var arr = localStorage.getItem("permission");
				if (arr) {
					that.permission = JSON.parse(arr);
				}
			},
			editbtn() {
				if (navigator.onLine == true) {
					var _that = this;
					window.location.href =
						_that.COMMON.canChangeUrl +
						"?redirect_uri=" +
						_that.COMMON.quizUrl +
						"&client_id=" +
						_that.COMMON.clientId +
						"&power=" +
						_that.COMMON.power + "&verSion=" + _that.COMMON.version;
				} else {
					this.$alert("请检查当前网络!", "提示", {
						confirmButtonText: "确定",
						type: "error",
						callback: action => {}
					});
				}
			},
			// 退出登录
			exitbtn() {
				if (navigator.onLine == true) {
					let _that = this;
					_that.$axios
						.post("/oauth/logout")
						.then(function(res) {
							if (res.data == true) {
								Cookies.remove("accessToken", {
									path: "",
									domain: _that.COMMON.domain
								});
								Cookies.remove("refreshToken", {
									path: "",
									domain: _that.COMMON.domain
								});
								Cookies.remove("expires", {
									path: "",
									domain: _that.COMMON.domain
								});
								Cookies.remove("scope", {
									path: "",
									domain: _that.COMMON.domain
								});
								localStorage.removeItem("userName");
								localStorage.removeItem("permission");
								window.location.href =
									_that.COMMON.canhelpUrl +
									"?redirect_uri=" +
									_that.COMMON.quizUrl +
									"&client_id=" +
									_that.COMMON.clientId +
									"&power=" +
									_that.COMMON.power + "&verSion=" + _that.COMMON.version;
							}
						})
						.catch(function(error) {
							return false;
						});
				} else {
					this.$alert("请检查当前网络!", "提示", {
						confirmButtonText: "确定",
						type: "error",
						callback: action => {}
					});
				}
			},
			handleSelect(key, keyPath) {
				var that = this;
				if (navigator.onLine == true) {
					switch (key) {
						case '1':
							that.$router.push('/quizScope');
							that.breadcrumbItems = ['考试范围']
							break;
						case '2-1':
							that.$router.push('/quizUpload');
							that.breadcrumbItems = ['试题上传']
							break;
						case '2-2':
							that.$router.push('/quizList');
							that.breadcrumbItems = ['试题列表']
							break;
						case '3':
							that.$router.push('/exam');
							that.breadcrumbItems = ['试卷管理']
							break;
						case '4':
							that.$router.push('/quizSystem');
							that.breadcrumbItems = ['考试端']
							break;
						// case '5':
						// 	that.$router.push('/quizView');
						// 	that.breadcrumbItems = ['答题信息']
						// 	break;
					}
				} else {
					that.$alert("请检查当前网络!", "提示", {
						confirmButtonText: "确定",
						type: "error",
						callback: action => {}
					});
				}
			},
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
	.myHome {
		position: absolute;
		width: 100%;
		height: 100vh;
		display: flex;
		position: relative;
	}

	.headerLogo img {
		height: 60px;
		text-align: center;
		margin: 20px;
	}

	/* 左侧 */
	.homeLeft {
		height: 100vh;
		width: 220px;
		/* 左侧菜单背景 */
		background: #001529;

		&>>>.el-submenu .el-menu-item {
			min-width: auto !important;
		}
	}

	.el-menu {
		border-right: none;
		width: 200px;
		padding-left: 20px;

		ul {
			padding-left: 0px;
		}
	}
	.el-radio-button__inner i {
		font-size: 22px;
	}

	/* 右侧 */
	.homeRight {
		width: 100%;
		overflow: hidden;
		height: 100%;
		overflow-y: scroll;
		/* overflow-y: scroll; */
		border-left: 1px solid #e4e4e4;
		.rightContent {
			background: #f0f2f5;
			padding: 20px;
			
		}
	}

	/* 右侧顶部导航 */
	.menuNavTitle {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #e4e4e4;
		box-sizing: border-box;
		background: #FFFFFF;
	}

	.menuNavTitle>>>.el-breadcrumb__inner {}

	.el-radio-group>>>.el-radio-button__inner {
		border: none !important;
	}

	/* 顶部个人信息下拉框 */
	.headerInfo {
		/* float: right !important; */
		color: "#fff";
		display: flex;
		color: #FFFFFF;
		font-size: 14px;
		height: 100%;
		cursor: pointer;
		.p1{
			background: #000000;
			padding: 0px 10px;
		}
		.p2{
			background: #D7A24A;
			padding: 0px 10px;
		}
		.p3{
			background: #D16947;
			padding: 0px 10px;
		}
	}

	/* 响应式 */
	@media only screen and (max-width: 1200px) {}

	@media only screen and (max-width: 992px) {}

	@media only screen and (max-width: 768px) {}

	@media only screen and (max-width: 576px) {}

	@media only screen and (min-width: 1200px) {}
</style>
